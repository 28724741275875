




















































import Vue from 'vue';

export default Vue.extend({
  name: 'ProcessedFile',
  data() {
    return {
      processing: true,
      result: false,
    };
  },
  methods: {
    checkStatusJob() {
      setTimeout(() => {
        this.loadJob();
      }, 1000);
    },
    async loadJob() {
      const resp = await this.$store.dispatch('demo/loadJobUUID');
      if (resp.Status === 'processing') {
        this.checkStatusJob();
        return;
      }
      this.processing = false;
      this.result = resp;
    },
    setRoute(route: string): void {
      this.$router.push({ path: route });
    },
    cleanData(): void {
      this.result = false;
      this.$emit('clean-data', true);
    },
  },
  computed: {
    loadFile() {
      return this.$store.getters['demo/getFile'];
    },
    fileType() {
      return this.$store.getters['demo/getFileType'];
    },
  },
  async mounted() {
    this.loadJob();
  },
});
