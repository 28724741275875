

































import Vue from 'vue';
import CardServices from './components/CardServices.vue';

export default Vue.extend({
  name: 'Api',
  components: {
    CardServices,
  },
  data() {
    return {
      tabs: null,
    };
  },
  computed: {
    tabList() {
      return [
        {
          name: this.$t('api.finance.name'),
          key: 'finance',
          img: 'moedas-do-dolar',
          services: {
            section1: [
              'rg',
              'rglite',
              'cnh',
              'cnhlite',
              'proofofaddress',
              'passport',
              'rne',
              'idtypification',
            ],
            section2: ['crv', 'crlv', 'bankcheck'],
          },
        },
        {
          name: this.$t('api.tax'),
          key: 'tax',
          img: 'calcular-imposto',
          services: {
            section1: ['danfe', 'irpf', 'nfse'],
            section2: ['send'],
          },
        },
        {
          name: this.$t('api.legal'),
          key: 'legal',
          img: 'licenca',
          services: {
            section1: ['contract', 'lawsuitregistration'],
            section2: ['send'],
          },
        },
        {
          name: this.$t('api.health'),
          key: 'health',
          img: 'prescricao',
          services: {
            section1: ['guide', 'healthcard'],
            section2: [],
          },
        },
        {
          name: this.$t('api.utilities'),
          key: 'utilities',
          img: 'velocimetro',
          services: {
            section1: ['energymeter'],
            section2: [],
          },
        },
        {
          name: this.$t('api.surveillance'),
          key: 'surveillance',
          img: 'camera-de-seguranca',
          services: {
            section1: ['send'],
            section2: [],
          },
        },
      ];
    },
  },
});
