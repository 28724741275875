import { ActionTree, MutationTree } from 'vuex';
import {
  CognitoUserPool,
  CognitoUserAttribute,
  CognitoUser,
  AuthenticationDetails,
} from 'amazon-cognito-identity-js';

import { StoreInterface, User } from '@/models';

export interface AuthState {
  userPool: CognitoUserPool;
  currentUser: User | null;
  cognitoUser: CognitoUser | null;
  expireToken: number | null;
  accessToken: string;
  idToken: string;
  loggedIn: boolean;
}

export interface UserState {
  email: string;
}

export interface LoginArgs {
  username: string;
  password: string;
}

export interface ResetPasswordConfirm {
  code: string;
  newPassword: string;
}

export interface AccountArgs {
  name: string;
  email: string;
  password: string;
  cpfCnpj: string;
  address: string;
  contact: string;
}

const initialState: AuthState = {
  userPool: new CognitoUserPool({
    UserPoolId: process.env.VUE_APP_ULTRA_COGNITO_POOL_ID,
    ClientId: process.env.VUE_APP_ULTRA_COGNITO_CLIENT_ID,
  }),
  currentUser: null,
  cognitoUser: null,
  expireToken: null,
  accessToken: '',
  idToken: '',
  loggedIn: false,
};

const parseUserData = (attributes: any): User => {
  const keys = new Map();
  keys.set('sub', 'id');
  keys.set('email', 'email');
  keys.set('email_verified', 'emailVerified');
  keys.set('name', 'name');
  keys.set('phone_number', 'phoneNumber');
  keys.set('phone_number_verified', 'phoneNumberVerified');
  keys.set('address', 'address');

  const result = attributes.reduce((user, item) => {
    const key: string = keys.get(item.Name);
    if (key) user[`${key}`] = item.Value;
    return user;
  }, {});
  return result;
};

const getters = {
  getToken(state: AuthState): string {
    return state.idToken;
  },

  getAccessToken(state: AuthState): string {
    return state.accessToken;
  },

  getIdToken(state: AuthState): string {
    return state.idToken;
  },

  getLoggedIn(state: AuthState): boolean {
    const now: number = Math.floor((new Date() as any) / 1000);
    if (state.expireToken && now < state.expireToken) {
      return true;
    }
    return false;
  },

  getCurrentUser(state: AuthState): User | null {
    return state.currentUser;
  },
};
const actions: ActionTree<AuthState, StoreInterface> = {
  async loadSession({ state, dispatch, commit }): Promise<any> {
    return new Promise((resolve, reject) => {
      state.cognitoUser = state.userPool.getCurrentUser();
      if (state.cognitoUser === null) {
        dispatch('logout');
        resolve('usuario nao encontrado');
        return;
      }

      try {
        state.cognitoUser.getSession(async (err, session) => {
          if (err || session === null) {
            if (err) console.log(err);
            await dispatch('logout');
            resolve('sessao invalida');
            return;
          }
          const now: number = Math.floor((new Date() as any) / 1000);
          if (now > session.accessToken.getExpiration()) {
            await dispatch('logout');
            resolve('token expirado');
            return;
          }
          commit('setExpireToken', session.accessToken.getExpiration());
          commit('setCurrentUser');
          commit('setAccessToken', session.accessToken.getJwtToken());
          commit('setIdToken', session.idToken.getJwtToken());
          resolve('sessao carregada');
        });
      } catch (error) {
        console.log('error in getSession');
        dispatch('logout');
        throw TypeError(error);
      }
    });
  },

  login({ state, commit }, args: LoginArgs): Promise<any> {
    return new Promise((resolve, reject) => {
      const authenticationDetails = new AuthenticationDetails({
        Username: args.username,
        Password: args.password,
      });

      const userData = {
        Username: args.username,
        Pool: state.userPool,
      };
      state.cognitoUser = new CognitoUser(userData);
      state.cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: async (result) => {
          const accessToken = await result.getAccessToken();
          const idToken = await result.getIdToken();

          commit('setAccessToken', accessToken.getJwtToken());
          commit('setIdToken', idToken.getJwtToken());
          commit('setExpireToken', accessToken.getExpiration());
          commit('setCurrentUser');
          resolve(state.currentUser);
        },
        onFailure: (err) => {
          console.log('Login Error!');
          console.log(err.message || JSON.stringify(err));
          reject(err);
        },
      });
    });
  },

  logout({ state, commit }): void {
    if (state.cognitoUser) state.cognitoUser.signOut();

    commit('setExpireToken', null);
    commit('setAccessToken', '');
    commit('setIdToken', '');
    commit('removeCurrentUser');
  },

  createAccount({ state }, args: AccountArgs): Promise<any> {
    return new Promise((resolve, reject) => {
      const attributeList: CognitoUserAttribute[] = [
        new CognitoUserAttribute({ Name: 'name', Value: args.name }),
        new CognitoUserAttribute({ Name: 'email', Value: args.email }),
        new CognitoUserAttribute({ Name: 'custom:document', Value: args.cpfCnpj }),
        new CognitoUserAttribute({ Name: 'address', Value: args.address }),
        new CognitoUserAttribute({
          Name: 'phone_number',
          Value: `+55${args.contact.replace(/[^0-9]/g, '')}`,
        }),
      ];

      state.userPool.signUp(args.email, args.password, attributeList, [], (err, result) => {
        if (err) {
          reject(err);
        }

        if (result) {
          state.cognitoUser = result.user;
          resolve(result.user);
        }
      });
    });
  },

  resendConfirmation({ state }): Promise<any> {
    return new Promise((resolve, reject) => {
      if (state.cognitoUser) {
        state.cognitoUser.resendConfirmationCode((err, result) => {
          if (err) {
            reject(err);
          }
          resolve(result);
        });
      }
    });
  },

  resetPassword({ state }, email: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const userData = {
        Username: email,
        Pool: state.userPool,
      };

      state.cognitoUser = new CognitoUser(userData);
      state.cognitoUser.forgotPassword({
        onSuccess: async (result) => {
          resolve(result);
        },
        onFailure: (err) => {
          reject(err);
        },
      });
    });
  },

  resetPasswordConfirm({ state }, args: ResetPasswordConfirm): Promise<any> {
    return new Promise((resolve, reject) => {
      if (state.cognitoUser) {
        state.cognitoUser.confirmPassword(args.code, args.newPassword, {
          onSuccess: async () => {
            resolve('');
          },
          onFailure: (err) => {
            reject(err);
          },
        });
      }
    });
  },
};

const mutations: MutationTree<AuthState> = {
  setCurrentUser(state: AuthState): void {
    if (state.cognitoUser) {
      state.cognitoUser.getUserAttributes((err, attributes) => {
        if (err) {
          console.log(err);
        } else {
          const currentUser: User = parseUserData(attributes);
          state.currentUser = currentUser;
        }
      });
    }
  },

  removeCurrentUser(state: AuthState): void {
    state.currentUser = null;
  },

  setAccessToken(state: AuthState, accessToken): void {
    state.accessToken = accessToken;
  },

  setIdToken(state: AuthState, idToken): void {
    state.idToken = idToken;
  },

  setExpireToken(state: AuthState, expireToken): void {
    state.expireToken = expireToken;
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
