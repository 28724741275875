import { ActionTree, MutationTree } from 'vuex';
import axios from 'axios';
import { StoreInterface } from '@/models';
import moment from 'moment';
import { formatNumber } from '@/utils/helpers';

export interface DataRequest {
  labels: Array<string>;
  values: Array<number>;
}

moment.locale('pt-br');

export interface MetricState {
  averageTime: string;
  serviceLast: Array<string>;
  requestPerHour: DataRequest;
  dataRequestPerService: DataRequest;
  dataRequestMonthly: DataRequest;
  totalErrors: number;
  totalRequests: number;
  uptime: string;
}

const initialState: MetricState = {
  serviceLast: [],
  requestPerHour: { labels: [], values: [] },
  dataRequestPerService: { labels: [], values: [] },
  dataRequestMonthly: { labels: [], values: [] },
  totalErrors: 0,
  totalRequests: 0,
  uptime: '',
  averageTime: '',
};

const getters = {
  getlastServiceUsed(state: MetricState) {
    return state.serviceLast;
  },
  getRequestPerHour(state: MetricState) {
    return state.requestPerHour;
  },
  getTotalErrors(state: MetricState) {
    return state.totalErrors;
  },
  getTotalRequests(state: MetricState) {
    return formatNumber(state.totalRequests);
  },
  getDataRequestPerService(state: MetricState) {
    return state.dataRequestPerService;
  },
  getDataRequestMonthly(state: MetricState) {
    return state.dataRequestMonthly;
  },
  getUptime(state: MetricState) {
    return state.uptime;
  },
  getAverageTime(state: MetricState) {
    return state.averageTime;
  },
};

const actions: ActionTree<MetricState, StoreInterface> = {
  async lastServiceUsed({ commit, rootGetters }) {
    const url = `${process.env.VUE_APP_ULTRA_OCR_API_BASE_URL_V1}/ocr/book/last_used`;
    try {
      const token = rootGetters['auth/getAccessToken'];
      const head = {
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.get(url, { headers: head });
      const lastService = [response.data.Service, response.data.Quantity];
      commit('setLastUsedService', lastService);
    } catch (error) {
      console.log('error in lastServiceUsed');
      throw TypeError(error);
    }
  },

  async requestPerHour({ commit, rootGetters }) {
    const url = `${process.env.VUE_APP_ULTRA_OCR_API_BASE_URL_V1}/ocr/book/total_requests`;
    try {
      const token = rootGetters['auth/getAccessToken'];
      const head = {
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.get(url, { headers: head });
      const requestLabel: Array<string> = [];
      const dateLabel = new Date(moment().format());
      for (let num = 0; num <= 22; num += 1) {
        dateLabel.setHours(dateLabel.getHours() - 1);
        requestLabel.push(dateLabel.getHours().toString());
      }
      const data: DataRequest = {
        labels: requestLabel,
        values: response.data.requests,
      };
      const totalErrors = response.data.total_errors;
      commit('setRequestPerHour', data);
      commit('setTotalErrors', totalErrors);
    } catch (error) {
      console.log('error in requestPerHour');
      throw TypeError(error);
    }
  },

  async requestPerService({ commit, rootGetters }) {
    const firstDayOfMonth = moment().format('Y-MM-01');
    const followingDay = moment()
      .add(1, 'days')
      .format('Y-MM-DD');
    const url = `${process.env.VUE_APP_ULTRA_OCR_NU_FRONT_API_BASE_URL}/book/summary?startDate=${firstDayOfMonth}&endDate=${followingDay}`;

    try {
      const token = rootGetters['auth/getAccessToken'];
      const head = {
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(url, { headers: head });
      const requestPerService = Object.keys(response.data).length ? response.data.products : [];
      const data: DataRequest = {
        labels: [],
        values: [],
      };

      Object.entries(requestPerService).forEach((resp: any) => {
        data.labels.push(resp[0]);
        data.values.push(resp[1].total);
      });

      commit('setDataRequestPerService', data);
    } catch (error) {
      console.log('error in requestPerService');
      throw TypeError(error);
    }
  },
  async monthRequests({ commit, rootGetters }) {
    const firstDayOfMonth = moment().format('Y-MM-01');
    const followingDay = moment()
      .add(1, 'day')
      .format('Y-MM-DD');
    const getDateArray = () => Array.from({ length: moment().daysInMonth() }, (_, i) => `${i + 1}`);
    const url = `${process.env.VUE_APP_ULTRA_OCR_NU_FRONT_API_BASE_URL}/book/summary?startDate=${firstDayOfMonth}&endDate=${followingDay}`;

    try {
      const token = rootGetters['auth/getAccessToken'];
      const head = {
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.get(url, { headers: head });
      const monthlyRequest = response.data.totalJobsDate ? response.data.totalJobsDate : 0;
      const monthDays = getDateArray();
      const totalRequest = response.data.total ? response.data.total : 0;
      const data: DataRequest = {
        labels: monthDays,
        values: [],
      };
      monthDays.forEach(() => {
        data.values.push(0);
      });
      Object.entries(monthlyRequest).forEach((resp: any) => {
        monthDays.forEach((item, index) => {
          if (resp[0].split('/')[0].replace('0', '') === item) {
            data.values[index] = resp[1].count;
          }
        });
      });
      commit('setDataRequestMonthly', data);
      commit('setTotalRequests', totalRequest);
    } catch (error) {
      console.log('error in monthRequests');
      throw TypeError(error);
    }
  },
  async uptime({ commit, rootGetters }) {
    const url = `${process.env.VUE_APP_ULTRA_OCR_API_BASE_URL_V1}/monitoring/uptime`;

    try {
      const token = rootGetters['auth/getAccessToken'];
      const head = {
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(url, { headers: head });
      const uptime = `${(response.data.Uptime * 100).toFixed(2)}%`;
      commit('setUptime', uptime);
    } catch (error) {
      console.log('error in uptime');
      throw TypeError(error);
    }
  },
  async averageTime({ commit, rootGetters }) {
    const today = moment().format('Y-MM-DD');
    const followingDay = moment()
      .add(1, 'day')
      .format('Y-MM-DD');

    const url = `${process.env.VUE_APP_ULTRA_OCR_NU_FRONT_API_BASE_URL}/book/summary?startDate=${today}&endDate=${followingDay}`;

    try {
      const token = rootGetters['auth/getAccessToken'];
      const head = {
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(url, { headers: head });
      const average = response.data.averageTime;
      commit('setAverageTime', average);
    } catch (error) {
      console.log('error in uptime');
      throw TypeError(error);
    }
  },
};

const mutations: MutationTree<MetricState> = {
  setLastUsedService(state: MetricState, status: string[]) {
    state.serviceLast = status;
  },
  setRequestPerHour(state: MetricState, status: DataRequest) {
    state.requestPerHour = status;
  },
  setTotalErrors(state: MetricState, status: number) {
    state.totalErrors = status;
  },
  setTotalRequests(state: MetricState, status: number) {
    state.totalRequests = status;
  },
  setDataRequestPerService(state: MetricState, data: DataRequest) {
    state.dataRequestPerService = data;
  },
  setDataRequestMonthly(state: MetricState, data: DataRequest) {
    state.dataRequestMonthly = data;
  },
  setUptime(state: MetricState, data: string) {
    state.uptime = data;
  },
  setAverageTime(state: MetricState, data: string) {
    state.averageTime = data;
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
