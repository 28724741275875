
















import Vue from 'vue';

import DashboardDataCards from './components/DashboardDataCards.vue';
import DashboardMetrics from './components/DashboardMetrics.vue';
import RequestPerService from './components/RequestPerService.vue';
import MonthlyRequests from './components/MonthlyRequests.vue';

export default Vue.extend({
  name: 'Dashboard',

  components: {
    DashboardDataCards,
    DashboardMetrics,
    RequestPerService,
    MonthlyRequests,
  },

  computed: {
    loggedIn(): boolean {
      return this.$store.getters['auth/getLoggedIn'];
    },
  },
});
