<template>
  <v-hover v-slot="{ hover }" close-delay="10">
    <v-card min-height="136" :elevation="hover ? 0 : 0" :class="{ 'on-hover': hover }" outlined>
      <v-list-item class="color-on-hover" three-line>
        <v-list-item-content class="color-on-hover">
          <div
            class="mt-2 mx-auto
            text-center text-h6 font-weight-bold gray--text color-on-hover text-size"
          >
            {{ title }}
          </div>
          <div
            class="mb-3 text-center font-weight-bold black--text text-h3 color-on-hover"
            v-if="loaded"
          >
            {{ data }}
          </div>
          <loading :size="51" class="pa-1" v-else />
          <v-list-item-subtitle class="mb-n2 gray--text text-caption color-on-hover">
            {{ subtitle }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-card>
  </v-hover>
</template>

<script>
import Loading from './Loading.vue';

export default {
  name: 'Card',
  props: ['title', 'data', 'subtitle', 'loaded'],
  components: {
    Loading,
  },
};
</script>

<style lang="scss" scoped>
.on-hover {
  .color-on-hover {
    color: #3751ff !important;
  }
}

.v-card:hover {
  border: 1px solid #3751ff !important;
  box-shadow: 0px 0px 4px 0px rgba(55, 81, 255, 0.75) !important;
  -webkit-box-shadow: 0px 0px 4px 0px rgba(55, 81, 255, 0.75) !important;
  -moz-box-shadow: 0px 0px 4px 0px rgba(55, 81, 255, 0.75) !important;
}
.text-size {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
