import VueI18n from 'vue-i18n';
import { ActionTree, MutationTree } from 'vuex';
import { StoreInterface } from '@/models';

export interface I18nState {
  defaultLocale: string;
}

interface Payload {
  i18n: VueI18n;
  locale: string;
}

const initialState: I18nState = {
  defaultLocale: localStorage.getItem('current-locale') || process.env.VUE_APP_ULTRA_OCR_I18N_LOCALE,
};

const getters = {
  getCurrentLocale(state: I18nState): string {
    return state.defaultLocale;
  },
};

const actions: ActionTree<I18nState, StoreInterface> = {};

const mutations: MutationTree<I18nState> = {
  setCurrentLocale(state: I18nState, payload: Payload): void {
    const { i18n, locale } = payload;
    i18n.locale = locale;
    localStorage.setItem('current-locale', locale);
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
