<template>
  <div style="border-radius: 50px">
    <prism style="heigth: 150px" :language="language"  :code="code"></prism>
  </div>
</template>

<script>

import Prism from 'vue-prismjs';
import 'prismjs/themes/prism-tomorrow.css';

export default {
  components: {
    Prism,
  },
  props: [
    'code',
    'language',
  ],
};
</script>
