

















































































import Vue from 'vue';
import HTTPSnippet from 'httpsnippet';
import CodeArea from './CodeArea.vue';

export default Vue.extend({
  components: {
    CodeArea,
  },
  data() {
    return {
      tab: null,
      progLang: [
        {
          key: 'go',
          language: 'go',
          copied: false,
        },
        {
          key: 'python',
          language: 'python',
          copied: false,
        },
        {
          key: 'node',
          language: 'javascript',
          copied: false,
        },
      ],
      thingToCopy: '',
      snackbar: false,
      timeout: 2000,
    };
  },
  props: ['serviceName'],
  methods: {
    snippetBuilder(lang: string) {
      const token = this.$store.getters['auth/getToken'];
      const snippet: HTTPSnippet = new HTTPSnippet({
        method: 'POST',
        url: `${process.env.VUE_APP_ULTRA_OCR_API_BASE_URL}/ocr/${this.serviceName}`,
        headers: [
          {
            name: 'Authorization',
            value: `Bearer ${token}`,
          },
          {
            name: 'Content-Type',
            value: 'application/json',
          },
        ],
        httpVersion: 'HTTP/1.1',
        cookies: [],
        queryString: [],
        postData: {
          mimeType: 'application/json',
          text: '{ "data": "// here goes your BASE-64-ENCODED-FILE" }',
        },
        headersSize: 0,
        bodySize: 0,
      });

      if (lang === 'curl') {
        return snippet.convert('shell', 'curl', { indent: '\t' });
      }
      return snippet.convert(lang);
    },
  },
});
