<template>
  <v-container class="pa-0 my-n3">
    <v-card outlined>
      <v-row class="my-n3">
        <v-col :md="8" :sm="12">
          <v-card-title class="pl-5">
            {{ $t('dashboard.metrics.today-metrics') }}
          </v-card-title>
          <v-card-subtitle class="pl-5 mb-n2 gray--text text-caption color-on-hover">
            {{ $t('dashboard.metrics.last-24-hours') }}
          </v-card-subtitle>
          <ChartLine class="pl-3" :chartdata="chartData" :options="configOptions" v-if="ready" />
          <loading :size="250" class="pa-16" v-else />
        </v-col>

        <v-col :md="4">
          <div class="divider-border" v-for="(item, index) in sideBar" :key="index">
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title
                  class="my-2 mx-auto text-center text-subtitle-1 gray--text"
                  v-text="$t(`dashboard.metrics.side-bar.item-${index + 1}`)"
                >
                </v-list-item-title>
                <v-list-item-subtitle
                  class="mb-3 text-center font-weight-bold black--text text-h5"
                  v-if="ready"
                >
                  {{
                    index === 0 && item
                      ? $t(`api.finance.${item.toLowerCase()}.name`).toUpperCase()
                      : item
                      ? item
                      : '--'
                  }}
                </v-list-item-subtitle>
                <loading :size="35" class="pa-1" v-else />
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import ChartLine from './ChartLine.vue';
import Loading from '../../../components/Loading.vue';

export default {
  name: 'DashboardMetrics',
  components: {
    ChartLine,
    Loading,
  },
  data: () => ({
    ready: false,
    date: '',
    label: 'today',
    chartData: {
      labels: [],
      datasets: [
        {
          label: '',
          backgroundColor: 'rgba(55, 81, 255, 0.1)',
          borderColor: '#3751ff',
          borderWidth: 2,
          pointRadius: 0,
          pointHitRadius: 10,
          pointBorderWidth: 2,
          data: [],
        },
        {
          label: '',
          backgroundColor: 'rgba(55, 81, 255, 0.1)',
          borderColor: '#C4C4C4',
          borderWidth: 2,
          pointRadius: 0,
          pointHitRadius: 10,
          pointBorderWidth: 2,
          data: [],
        },
      ],
    },
    chartOptions: {
      pointRadius: 6,
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: {
          left: 20,
          right: 10,
          top: 40,
          bottom: 10,
        },
      },
      legend: {
        align: 'start',
      },
      title: false,
      tooltips: {
        backgroundColor: 'rgba(255,255,255,1)',
        bodyFontColor: 'rgba(37,39,51,1)',
        borderColor: 'rgba(223,224,235,1)',
        bodyFontSize: 16,
        bodyFontStyle: 'bold',
        borderWidth: 1,
        displayColors: false,
        xPadding: 30,
        yPadding: 15,
        position: 'nearest',

        callbacks: {
          /* eslint-disable */
          title: function(tooltipItems, data) {
            return '';
          },
          label: function(tooltipItem, data) {
            var datasetLabel = '';
            var label = data.labels[tooltipItem.index];
            return data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          },
        },
      },
      /* eslint-enable */
    },
  }),
  computed: {
    configOptions() {
      const optionsData = this.chartOptions;
      optionsData.scales = {
        xAxes: [
          {
            gridLines: {
              display: false,
            },
            scaleLabel: {
              display: true,
              labelString: this.$t('dashboard.metrics.hour'),
            },
          },
        ],
        yAxes: [
          {
            position: 'right',
            gridLines: {
              color: 'rgba(235,237,240,1)',
            },
            scaleLabel: {
              display: true,
              labelString: this.$t('dashboard.metrics.requests'),
            },
            ticks: {
              precision: 0,
              beginAtZero: true,
            },
          },
        ],
      };
      return optionsData;
    },
    sideBar() {
      const uptime = this.$store.getters['metrics/getUptime'];
      const average = this.$store.getters['metrics/getAverageTime'];
      const [lastService, requests] = this.$store.getters['metrics/getlastServiceUsed'];
      return [lastService, requests, average, uptime, '--'];
    },
  },
  async mounted() {
    await this.$store.dispatch('metrics/lastServiceUsed');
    await this.$store.dispatch('metrics/requestPerHour');
    await this.$store.dispatch('metrics/averageTime');

    const resp = await this.$store.getters['metrics/getRequestPerHour'];

    const values = resp.values.reverse();
    const labels = resp.labels.reverse();
    // get midnight index
    const midnightIndex = labels.indexOf('0');
    this.chartData.datasets[0].data = this.todayData(values, midnightIndex);
    this.chartData.datasets[1].data = this.yesterdayData(values, midnightIndex + 1);
    this.chartData.datasets[0].label = this.$t('dashboard.metrics.today');
    this.chartData.datasets[1].label = this.$t('dashboard.metrics.yesterday');
    this.chartData.labels = [...labels, this.$t('dashboard.metrics.now')];
    this.todayLabel = this.$t('dashboard.metrics.today');
    this.ready = true;
  },
  methods: {
    todayData(data, midnightIndex) {
      return data.map((item, index) => {
        if (index < midnightIndex) {
          return null;
        }

        return item;
      });
    },
    yesterdayData(data, midnightIndex) {
      return data.map((item, index) => {
        if (index >= midnightIndex) {
          return null;
        }

        return item;
      });
    },
  },
};
</script>

<style>
.divider-border {
  border-left: solid 2px #cccccc;
}
@media (max-width: 960px) {
  .divider-border {
    border-left: none;
  }
}
</style>
