







import { Vue } from 'vue-property-decorator';
import CreateAppClient from './components/CreateAppClient.vue';
import ListAppClient from './components/ListAppClient.vue';

export default Vue.extend({
  name: 'ApiClient',
  components: {
    CreateAppClient,
    ListAppClient,
  },
  mounted() {
    setTimeout(() => {
      this.$store.dispatch('appClient/loadApps');
    }, 1000);
  },
  computed: {
    listAppData() {
      return this.$store.getters['appClient/getAppClient'];
    },
  },
});
