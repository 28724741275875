










































































import Vue from 'vue';
import CardService from './CardService.vue';

export default Vue.extend({
  name: 'CardServices',
  props: ['service', 'services'],
  components: {
    CardService,
  },
  data() {
    return {
      dialog: false,
      disabledServices: [
        'contract',
        'crlv',
        'crv',
        'danfe',
        'guide',
        'healthcard',
        'lawsuitregistration',
        'proofofaddress',
        'bankcheck',
        'cnhlite',
        'passport',
        'rglite',
      ],
    };
  },
  methods: {
    openService(link: string) {
      if (this.disabledServices.includes(link)) {
        this.dialog = true;
      } else {
        this.$router.push({ path: `demo/${link}` });
      }
    },
    openSuport(): void {
      window.open('https://nuveoai.atlassian.net/servicedesk/customer/portal/4', '_blank');
    },
  },
});
