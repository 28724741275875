import { OptionSnackBar } from '@/models';

export interface AppState {
  sidebarStatus: boolean;
  showSnackBar: boolean;
  optionsSnackBar: OptionSnackBar;
  isLoading: boolean;
}

const initialState: AppState = {
  sidebarStatus: true,
  showSnackBar: false,
  optionsSnackBar: {},
  isLoading: false,
};

const getters = {
  getSidebarStatus(state: AppState) {
    return state.sidebarStatus;
  },
  getStatusSnackBar(state: AppState) {
    return state.showSnackBar;
  },
  getOptionsSnackBar(state: AppState) {
    return state.optionsSnackBar;
  },
  loading(state: AppState) {
    return state.isLoading;
  },
};

const actions = {};

const mutations = {
  toggleSideBarStatus(state: AppState, status: boolean) {
    state.sidebarStatus = status;
  },
  callSnackBar(state: AppState, options: OptionSnackBar) {
    state.showSnackBar = true;
    state.optionsSnackBar = options;
    setTimeout(() => {
      state.showSnackBar = false;
      state.optionsSnackBar = {};
    }, 5000);
  },
  resetSnackBar(state: AppState) {
    state.showSnackBar = false;
    state.optionsSnackBar = {};
  },
  setLoading(state: AppState, value: boolean) {
    state.isLoading = value;
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
