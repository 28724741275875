

























import Vue from 'vue';

export default Vue.extend({
  name: 'LangButton',
  props: ['display-drawer'],
  data: () => ({
    languages: ['pt', 'en'],
  }),
  methods: {
    toggleLang(locale: string): void {
      this.$store.commit('i18n/setCurrentLocale', { i18n: this.$i18n, locale });
    },
  },
});
