








































import { NavItem } from '@/models';
import router from '@/router';
import Vue from 'vue';

export default Vue.extend({
  name: 'side-bar',

  computed: {
    drawer: {
      get(): boolean {
        return this.$store.getters['app/getSidebarStatus'];
      },
      set(status: boolean): void {
        this.$store.commit('app/toggleSideBarStatus', status);
      },
    },
    dashboardItems(): NavItem[] {
      return [
        {
          text: this.$t('sidebar.nav.item-1'),
          icon: 'mdi-chart-pie',
          link: '/',
          current: this.$route.path === '/',
          target: false,
        },
        {
          text: this.$t('sidebar.nav.item-2'),
          icon: 'mdi-ticket-confirmation',
          link: '/billing',
          current: this.$route.path === '/billing',
          target: false,
        },
        {
          text: this.$t('sidebar.nav.item-3'),
          icon: 'fas fa-cogs',
          link: '/api',
          current: this.$route.path === '/api' || this.$route.path.includes('demo'),
          target: false,
        },
        {
          text: this.$t('sidebar.nav.item-4'),
          icon: 'fas fa-key',
          link: '/api-client',
          current: this.$route.path === '/api-client',
          target: false,
        },
        {
          text: this.$t('sidebar.nav.item-5'),
          icon: 'mdi-account-multiple-outline',
          link: 'https://nuveoai.atlassian.net/servicedesk/customer/portal/4',
          current: false,
          target: true,
        },
        {
          text: this.$t('sidebar.nav.item-6'),
          icon: 'fas fa-book',
          link: `${process.env.VUE_APP_ULTRA_OCR_DOCS_REDIRECT}`,
          current: false,
          target: true,
        },
        {
          text: this.$t('sidebar.nav-config.item-2'),
          icon: 'mdi-account-cog',
          link: '/account',
          current: this.$route.path === '/account',
          target: false,
        },
      ];
    },
  },
  data: () => ({
    item: 0,
  }),
  watch: {
    $route: 'currentRoute',
  },
  methods: {
    setPath(link, newWindow) {
      if (newWindow) {
        window.open(link, '_blank');
      } else {
        router.push(link);
      }
    },
    currentRoute() {
      this.dashboardItems.some((item, index) => {
        if (this.$route.path === item.link) {
          this.item = index;
          return true;
        }
        return false;
      });
    },
  },
});
