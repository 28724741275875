






import Vue from 'vue';

export default Vue.extend({
  name: 'Loader',
  data: () => ({
    overlay: true,
    zIndex: 9,
  }),
});
