


























































import Vue from 'vue';
import LangButton from './LangButton.vue';

export default Vue.extend({
  name: 'Header',

  components: {
    LangButton,
  },

  data: () => ({
    currentLang: 'pt',
    languages: ['pt', 'en'],
  }),

  computed: {
    loggedIn(): boolean {
      return this.$store.getters['auth/getLoggedIn'];
    },
    hasOpen(): boolean {
      return this.$store.getters['app/getSidebarStatus'];
    },
    userName(): string {
      const resp = this.$store.getters['auth/getCurrentUser'];
      return resp?.name;
    },
  },

  methods: {
    toggleSideBar(): void {
      this.$store.commit('app/toggleSideBarStatus', !this.$store.getters['app/getSidebarStatus']);
    },
    logOut() {
      this.$store.dispatch('auth/logout');
      window.location.href = '/';
    },
  },
});
