


















import Vue from 'vue';
import Header from './components/Layout/Header.vue';
import SideBar from './components/Layout/SideBar.vue';
import SnackBar from './components/SnackBar.vue';
import Loader from './components/Loader.vue';

export default Vue.extend({
  name: 'App',

  components: {
    Header,
    SideBar,
    SnackBar,
    Loader,
  },

  data: () => ({
    currentLang: 'pt',
    languages: ['pt', 'en'],
  }),

  computed: {
    loggedIn(): boolean {
      return this.$store.getters['auth/getLoggedIn'];
    },
    isLoading(): boolean {
      return this.$store.getters['app/loading'];
    },
  },
});
