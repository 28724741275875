










import Vue from 'vue';

export default Vue.extend({
  name: 'Loading',
  props: ['size'],
  data: () => ({
    actived: { type: Boolean, default: false },
  }),
});
