/* eslint-disable @typescript-eslint/camelcase */
import { ActionTree, MutationTree } from 'vuex';
import axios from 'axios';
import { StoreInterface } from '@/models';

export interface Invoice {
  service: string;
  totalSent: number;
  error: number;
  total: number;
  invoiceTotal: number;
}

export interface BillingState {
  invoice: Invoice;
  invoicePdf: Blob;
}

const initialState: BillingState = {
  invoice: {
    service: '',
    totalSent: 0,
    error: 0,
    total: 0,
    invoiceTotal: 0,
  },
  invoicePdf: new Blob(),
};

const getters = {
  getInvoice(state: BillingState) {
    return state.invoice;
  },
  getInvoiceTotal(state: BillingState) {
    return state.invoice.invoiceTotal;
  },
  getPdfInvoice(state: BillingState) {
    return state.invoicePdf;
  },
};

const actions: ActionTree<BillingState, StoreInterface> = {
  async lastInvoice({ commit, rootGetters }) {
    const url = `${process.env.VUE_APP_ULTRA_OCR_API_BASE_URL_V1}/ocr/book/invoices`;
    try {
      const token = rootGetters['auth/getAccessToken'];
      const head = {
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.get(url, { headers: head });
      const lastInvoice = response.data;
      const totalInvoice = response.data.total;
      commit('setInvoice', lastInvoice);
      commit('setInvoiceTotal', totalInvoice);
    } catch (error) {
      console.log('error in lastInvoice');
      throw TypeError(error);
    }
  },

  async printInvoice({ commit, rootGetters }) {
    try {
      const url = `${process.env.VUE_APP_ULTRA_OCR_API_BASE_URL_V1}/reports?template=invoice`;
      const token = rootGetters['auth/getAccessToken'];
      const user = rootGetters['auth/getCurrentUser'];
      const invoices = rootGetters['billing/getInvoice'].services;
      const date = new Date();
      const head = {
        Authorization: `Bearer ${token}`,
      };
      await axios({
        method: 'post',
        url,
        headers: head,
        responseType: 'blob',
        data: {
          client_data: {
            name: user.name,
            email: user.email,
          },
          invoice_data: {
            services: invoices,
            date,
          },
        },
      }).then((resp) => {
        const file = new Blob([resp.data], { type: 'application/pdf' });
        commit('setPdfInvoice', file);
      });
    } catch (err) {
      throw new Error('error in printInvoice');
    } finally {
      console.log('Download is Done');
    }
  },
};

const mutations: MutationTree<BillingState> = {
  setInvoice(state: BillingState, status: Invoice) {
    state.invoice = status;
  },
  setInvoiceTotal(state: BillingState, status) {
    state.invoice.invoiceTotal = status;
  },
  setPdfInvoice(state: BillingState, pdf: Blob) {
    state.invoicePdf = pdf;
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
