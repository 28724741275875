<template>
  <v-container class="pa-0 mb-n8">
    <v-card class="mx-auto my-2 pl-2 p" outlined>
      <v-card-title> {{ $t('dashboard.requests-ps.title') }} </v-card-title>
      <v-card-subtitle class="mb-n2 gray--text text-caption color-on-hover">
        {{ $t('dashboard.requests-ps.as-of', { date: date() }) }}
      </v-card-subtitle>
      <div class="mr-16 ml-10 my-7">
        <loading :size="155" class="pa-10" v-if="!ready && !hasData" />
        <ChartPie
          v-if="ready && hasData"
          :chartdata="chartData"
          :options="chartOptions"
          style="position: relative; height: 244px"
        />
      </div>
      <div v-if="!hasData && ready" class="mr-16 ml-10 my-7 text-center no-data" min-height="245">
        <span>
          {{ $t('dashboard.card.empty') }}
        </span>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import localeDate from '../../../utils/localeDate';
import ChartPie from './ChartPie.vue';
import Loading from '../../../components/Loading.vue';

export default {
  name: 'RequestPerService',
  components: {
    ChartPie,
    Loading,
  },
  data: () => ({
    ready: false,
    hasData: false,
    chartData: {
      labels: [],
      datasets: [
        {
          backgroundColor: [
            'rgba(107,123,236,1)',
            'rgba(195,202,244,1)',
            'rgba(232,235,255,1)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
          ],
          data: [],
        },
      ],
    },
    chartOptions: {
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
        },
      },
      legend: {
        align: 'center',
        position: 'bottom',
        labels: {
          boxWidth: 13,
        },
      },
      tooltips: {
        backgroundColor: 'rgba(255,255,255,1)',
        titleFontColor: 'rgba(37,39,51,1)',
        bodyFontColor: 'rgba(37,39,51,1)',
        borderColor: 'rgba(223,224,235,1)',
        borderWidth: 1,
        titleAlign: 'center',
        titleFontSize: 12,
        bodyFontSize: 16,
        displayColors: false,
      },
    },
  }),
  async mounted() {
    await this.$store.dispatch('metrics/requestPerService');
    const resp = await this.$store.getters['metrics/getDataRequestPerService'];
    if (resp.labels.length === 0 || resp.values.length === 0) {
      this.ready = true;
      this.hasData = false;
    } else {
      const newLabels = [];
      resp.labels.map((item) => {
        const newItem = this.$t(`api.finance.${item.toLowerCase()}`).name
          ? this.$t(`api.finance.${item.toLowerCase()}`).name
          : item;
        return newLabels.push(newItem.toUpperCase());
      });
      this.chartData.labels = newLabels;
      this.chartData.datasets[0].data = resp.values;
      this.ready = true;
      this.hasData = true;
    }
  },
  methods: {
    date() {
      return new Date().toLocaleDateString(localeDate(), {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
    },
  },
};
</script>

<style>
.no-data {
  height: 245px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
