





































































import Btn from '@/components/Btn.vue';
import Vue from 'vue';

export default Vue.extend({
  name: 'Billing',
  components: { Btn },
  data() {
    return {
      billing: '',
      date: new Date(),
    };
  },
  computed: {
    invoices(): any[] {
      return this.$store.getters['billing/getInvoice'].services;
    },
    total(): number {
      return this.$store.getters['billing/getInvoiceTotal'];
    },
  },
  async mounted() {
    await this.$store.dispatch('billing/lastInvoice');
  },
  methods: {
    billingDate() {
      this.billing = `01/${(this.date.getMonth() + 2).toString().padStart(2, '0')}/${this.date.getFullYear()}`;
      return this.billing;
    },
    async downloadInvoice() {
      await this.$store.dispatch('billing/printInvoice');
      const download = this.$store.getters['billing/getPdfInvoice'];
      const fileURL = URL.createObjectURL(download);
      return window.open(fileURL);
    },
  },

});
