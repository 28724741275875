import { ActionTree, MutationTree } from 'vuex';
import axios from 'axios';
import { StoreInterface } from '@/models';
import { ApiClient } from '@/models/api-client';
import moment from 'moment';
import store from '..';

export interface AppState {
  secure: boolean;
  scopes: string[];
  client_name: string;
  dataAppClient: ApiClient[];
  appCreated: ApiClient | null;
  hasDefaultApp: boolean;
}

const initialState: AppState = {
  secure: false,
  scopes: [],
  client_name: '',
  dataAppClient: [],
  appCreated: null,
  hasDefaultApp: false,
};

moment.locale('pt-br');

const getters = {
  getAppClient(state: AppState) {
    return state.dataAppClient;
  },
  getAppCreated(state: AppState) {
    return state.appCreated || {};
  },
  getHasDefaultApp(state: AppState) {
    return state.hasDefaultApp;
  },
};

const headers = (token: string) => ({ headers: { Authorization: `Bearer ${token}` } });
const url = `${process.env.VUE_APP_ULTRA_OCR_API_BASE_URL_V1}/clients`;

const actions: ActionTree<AppState, StoreInterface> = {
  async createApp({ commit, rootGetters }, data) {
    try {
      const token = rootGetters['auth/getAccessToken'];
      const user = rootGetters['auth/getCurrentUser'];
      data.user_email = user?.email;
      await axios.post(`${url}/internal`, data, headers(token)).then((response) => {
        commit('setAppCreated', response.data);
        store.dispatch('appClient/loadApps');
      });
    } catch (error) {
      console.log(error, 'error in createApp');
      throw TypeError(error);
    }
  },
  async loadApps({ commit, rootGetters }) {
    try {
      store.commit('app/setLoading', true);
      const token = rootGetters['auth/getAccessToken'];
      const user = rootGetters['auth/getCurrentUser'];
      const response = await axios.get(`${url}?owner=${user?.email}`, headers(token));

      commit('setDataAppClient', response.data);
      store.commit('app/setLoading', false);
    } catch (error) {
      store.commit('app/setLoading', false);
      console.log('error in getApps');
      throw TypeError(error);
    }
  },
  deleteApp({ rootGetters }, ClientId: string) {
    try {
      const token = rootGetters['auth/getAccessToken'];
      return axios.delete(`${url}/${ClientId}`, headers(token));
    } catch (error) {
      console.log('error in delete App');
      throw TypeError(error);
    }
  },
};

const mutations: MutationTree<AppState> = {
  setAppCreated(state: AppState, value: ApiClient) {
    state.appCreated = value;
  },
  setLastUsedService(state: AppState, status: string[]) {
    state.scopes = status;
  },
  setDataAppClient(state: AppState, data: Array<ApiClient>) {
    state.dataAppClient = data;
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
