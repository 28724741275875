<template>
  <v-container class="pa-0">
    <v-card class="mx-auto my-2 pl-2 p" outlined>
      <v-card-title> {{ $t('dashboard.month-request.title') }} </v-card-title>
      <v-card-subtitle class="mb-n2 gray--text text-caption color-on-hover text-capitalize">
        {{ getMonth() }}
      </v-card-subtitle>
      <div class="my-12 mx-5">
        <ChartLine
          v-if="hasData && ready"
          :chartdata="chartData"
          :options="configOptions"
          style="position: relative; height:100%; max-height: 204px;"
        />
        <loading :size="155" class="pa-5" v-if="!ready" />
      </div>
    </v-card>
  </v-container>
</template>

<script>
import ChartLine from './ChartLine.vue';
import localeDate from '../../../utils/localeDate';
import Loading from '../../../components/Loading.vue';

export default {
  name: 'MonthlyRequests',
  components: {
    ChartLine,
    Loading,
  },
  data: () => ({
    ready: false,
    hasData: false,
    date: '01/oct',
    chartData: {
      labels: [],
      datasets: [
        {
          label: '',
          backgroundColor: 'rgba(255,255,255, 0.1)',
          borderColor: '#3751ff',
          data: [],
          lineTension: false,
          borderWidth: 1,
          pointRadius: 0,
          pointHitRadius: 10,
        },
      ],
    },
    chartOptions: {
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
        },
      },
      legend: {
        display: false,
      },
      scales: {
        xAxes: [
          {
            gridLines: {
              display: false,
            },
          },
        ],
        yAxes: [
          {
            gridLines: {
              color: 'rgba(235,237,240,1)',
            },
            ticks: {
              precision: 0,
              beginAtZero: true,
              min: 1,
            },
          },
        ],
      },
      tooltips: {
        backgroundColor: 'rgba(255,255,255,1)',
        bodyFontColor: 'rgba(37,39,51,1)',
        borderColor: 'rgba(223,224,235,1)',
        borderWidth: 1,
        bodyFontSize: 16,
        bodyFontStyle: 'bold',
        displayColors: false,
        xPadding: 30,
        yPadding: 15,
        position: 'nearest',
        callbacks: {
          /* eslint-disable */
          title: function(tooltipItems, data) {
            return '';
          },
          label: function(tooltipItem, data) {
            var datasetLabel = '';
            var label = data.labels[tooltipItem.index];
            return data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          },
        },
        /* eslint-enable */
      },
    },
  }),
  computed: {
    configOptions() {
      const optionsData = this.chartOptions;
      optionsData.scales = {
        xAxes: [
          {
            gridLines: {
              display: false,
            },
            scaleLabel: {
              display: true,
              labelString: this.$t('dashboard.metrics.days'),
              fontColor: '#9FA2B4',
            },
            ticks: {
              fontColor: '#9FA2B4',
            },
          },
        ],
        yAxes: [
          {
            position: 'right',
            gridLines: {
              drawBorder: false,
            },
            scaleLabel: {
              display: true,
              labelString: this.$t('dashboard.metrics.requests'),
              fontColor: '#9FA2B4',
            },
            ticks: {
              precision: 0,
              beginAtZero: true,
              fontColor: '#9FA2B4', // changes label color font
              padding: 15,
            },
          },
        ],
      };
      return optionsData;
    },
  },
  async mounted() {
    await this.$store.dispatch('metrics/monthRequests');
    const resp = await this.$store.getters['metrics/getDataRequestMonthly'];
    if (resp.labels.length === 0 || resp.values.length === 0) {
      this.ready = true;
      this.hasData = false;
    } else {
      this.chartData.labels = resp.labels;
      this.chartData.datasets[0].data = resp.values;
      this.ready = true;
      this.hasData = true;
    }
  },
  methods: {
    getMonth() {
      return new Date().toLocaleString(localeDate(), { month: 'long' });
    },
  },
};
</script>

<style scoped></style>
