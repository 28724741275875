import { ActionTree, MutationTree } from 'vuex';
import axios from 'axios';
import { StoreInterface } from '@/models';

export interface DemoState {
  service: string;
  jobUUID: string;
  result: any;
  file: any;
  fileType: string;
}
export interface FileState {
  file: File;
  runner: string;
  pocDetran?: string;
}

const initialState: DemoState = {
  service: '',
  jobUUID: '',
  result: {},
  file: null,
  fileType: '',
};

const getHeaders = (token: string) => ({ Authorization: `Bearer ${token}` });

const getters = {
  getlastServiceUsed(state: DemoState) {
    return state.service;
  },
  getJobUUID(state: DemoState) {
    return state.jobUUID;
  },
  getFile(state: DemoState) {
    return state.file;
  },
  getFileType(state: DemoState) {
    return state.fileType;
  },
};

const actions: ActionTree<DemoState, StoreInterface> = {
  async uploadFile({ dispatch, commit, rootGetters }, args: FileState): Promise<string | void> {
    const { file, runner } = args;
    const url = `${process.env.VUE_APP_ULTRA_OCR_API_BASE_URL_V1}/ocr/${
      runner === 'send' ? 'extraction/pers-doc' : `${runner}`
    }`;
    const convertedFile = await dispatch('convertFile', file);
    commit('setFile', convertedFile);
    commit('setFileType', file.type);
    const data = {
      metadata: { languages: ['pt-BR'] },
      data: convertedFile.split(',').pop(),
    };
    try {
      const token = rootGetters['auth/getAccessToken'];
      const response = await axios.post(url, data, { headers: getHeaders(token) });
      commit('setJobUUID', response.data.JobUUID);
      return response.data;
    } catch (error) {
      console.log('error in uploadFile');
      throw TypeError(error);
    }
  },
  async convertFile(context, file) {
    try {
      const convert = (fileToBase64: any) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(fileToBase64);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        });
      return convert(file).then((data) => data);
    } catch (error) {
      console.log('error in convertFile');
      throw TypeError(error);
    }
  },
  async loadJobUUID({ state, dispatch, commit, rootGetters }): Promise<string | void> {
    const url = `${process.env.VUE_APP_ULTRA_OCR_API_BASE_URL_V1}/ocr/result/${state.jobUUID}`;
    try {
      const token = rootGetters['auth/getAccessToken'];
      const response = await axios.get(url, { headers: getHeaders(token) });
      commit('setResult', response.data.JobUUID);
      return response.data;
    } catch (error) {
      console.log('error in loadJobUUID');
      throw TypeError(error);
    }
  },
};

const mutations: MutationTree<DemoState> = {
  setService(state: DemoState, status: string) {
    state.service = status;
  },
  setJobUUID(state: DemoState, status: string) {
    state.jobUUID = status;
  },
  setResult(state: DemoState, result: any) {
    state.result = result;
  },
  setFile(state: DemoState, file: any) {
    state.file = file;
  },
  setFileType(state: DemoState, str: string) {
    const [type, ext] = str.split('/');
    state.fileType = ext;
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
