





















import Vue from 'vue';

export default Vue.extend({
  name: 'CardService',
  props: ['disabled', 'serviceName', 'image', 'serviceDescription', 'fullCard'],
  methods: {
    openService(event: string) {
      this.$emit('open-service', event);
    },
  },
});
