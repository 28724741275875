import Vue from 'vue';
import httpsnippet from 'httpsnippet';
import VueClipboard from 'vue-clipboard2';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import i18n from './i18n';
/* eslint-disable */
import ZoomOnHover from 'vue-zoom-on-hover';
import VueMask from 'v-mask';


Object.defineProperty(Vue.prototype, '$httpsnippet', { value: httpsnippet });
Vue.config.productionTip = false;
Vue.use(ZoomOnHover);
Vue.use(VueClipboard);
Vue.use(VueMask);

const initApp = async () => {
  await store.dispatch('auth/loadSession');
  new Vue({
    router,
    store,
    i18n,
    vuetify,
    render: (h) => h(App),
  }).$mount('#app');
};
initApp();
