<template>
  <v-container class="mt-5 pa-5" style="max-width: 1100px">
    <v-row justify="center">
      <v-col :lg="12">
        <v-card max-width="1056px" outlined>
          <v-row>
            <v-col :cols="12">
              <v-card-title class="pl-5 bold">
                {{ $t('account.title') }}
              </v-card-title>
            </v-col>
          </v-row>
          <v-form ref="form" v-model="valid" :disabled="!editMode" lazy-validation>
            <v-row justify="center" class="row-height">
              <v-col :cols="2" class="label-align">
                <v-header class="bold margin-text">
                  {{ $t('account.fields.f1') }}
                </v-header>
              </v-col>
              <v-col :cols="10">
                <v-text-field v-model="name" outlined required dense class="radius field-width" />
              </v-col>
            </v-row>

            <v-row justify="center" class="row-height">
              <v-col :cols="2" class="label-align">
                <v-header class="bold margin-text">
                  {{ $t('account.fields.f2') }}
                </v-header>
              </v-col>
              <v-col :cols="10">
                <v-text-field
                  v-model="cpfCnpj"
                  outlined
                  required
                  dense
                  class="radius field-width"
                />
              </v-col>
            </v-row>

            <v-row justify="center" class="row-height">
              <v-col :cols="2" class="label-align">
                <v-header class="bold margin-text">
                  {{ $t('account.fields.f3') }}
                </v-header>
              </v-col>
              <v-col :cols="10">
                <v-text-field
                  v-model="address"
                  outlined
                  required
                  dense
                  class="radius field-width"
                />
              </v-col>
            </v-row>

            <v-row justify="center" class="row-height">
              <v-col :cols="2" class="label-align">
                <v-header class="bold margin-text">
                  {{ $t('account.fields.f4') }}
                </v-header>
              </v-col>
              <v-col :cols="10">
                <v-text-field
                  v-model="contact"
                  outlined
                  required
                  dense
                  class="radius field-width"
                />
              </v-col>
            </v-row>

            <v-row justify="center" class="row-height">
              <v-col :cols="2" class="label-align">
                <v-header class="bold margin-text">
                  {{ $t('account.fields.f5') }}
                </v-header>
              </v-col>
              <v-col :cols="10">
                <v-text-field
                  v-model="email"
                  :rules="emailRules"
                  outlined
                  required
                  dense
                  class="radius field-width"
                />
              </v-col>
            </v-row>

            <v-row justify="center" class="row-height">
              <v-col :cols="2" class="label-align">
                <v-header class="bold margin-text">
                  {{ $t('account.fields.f6') }}
                </v-header>
              </v-col>
              <v-col :cols="10">
                <v-text-field
                  v-model="password"
                  outlined
                  required
                  dense
                  class="radius field-width"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPassword ? 'text' : 'password'"
                  @click:append="showPassword = !showPassword"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col :cols="4" class="label-align">
                <div class="button-margin">
                  <v-btn class="ma-2" outlined color="red" large>
                    {{ $t('account.buttons.btn1') }}
                  </v-btn>
                </div>
              </v-col>
              <v-col :cols="8" class="buttons-align">
                <div class="buttons-margin">
                  <v-btn
                    :disabled="editMode"
                    class="ma-2"
                    outlined
                    color="primary"
                    large
                    @click="edit"
                  >
                    {{ $t('account.buttons.btn2') }}
                  </v-btn>
                  <v-btn
                    :disabled="!editMode"
                    class="ma-2"
                    outlined
                    color="primary"
                    large
                    @click="save"
                  >
                    {{ $t('account.buttons.btn3') }}
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    valid: true,
    editMode: false,
    name: 'Jhon Doe',
    cpfCnpj: '123.456.789/0001-00',
    address: 'Street, number, city, state, Country',
    contact: '(xx) 9999-9999',
    email: 'company@email.com',
    password: '123456',
    showPassword: false,
    emailRules: [(v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'],
  }),
  methods: {
    forgetMe() {
      console.log('forget me');
    },
    edit() {
      this.editMode = true;
    },
    save() {
      console.log('saving');
      this.editMode = false;
    },
  },
  async mounted() {
    const currentUser = await this.$store.getters['auth/getCurrentUser'];
    await this.$store.dispatch('account/accountInfo', currentUser.uuid);
    const accountInfo = await this.$store.getters['account/getAccountInfo'];
    console.log(accountInfo);
  },
};
</script>

<style lang="scss" scope>
.radius {
  border-radius: 10px;
}
.bold {
  font-weight: bold;
}
.label-align {
  text-align: start;
}
.field-width {
  max-width: 75%;
}
.row-height {
  height: 82px;
}
.margin-text {
  margin-left: 40%;
}
.buttons-align {
  text-align: end;
}
.buttons-margin {
  margin-right: 10%;
}
.button-margin {
  margin-left: 16%;
}
.content-title {
  background-color: #123f54;
  border-radius: 5px;
  margin-bottom: 40px;
  height: 80px;
  justify-content: center;
}
.title {
  font-weight: bold;
  color: white;
  opacity: 0.9;
  padding-top: 10px;
  margin-left: 10px;
}
.div-actions {
  margin-top: 20px;
  text-align: center;
  margin-bottom: 20px;
}
</style>
