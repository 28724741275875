import { ActionTree, MutationTree } from 'vuex';
import axios from 'axios';
import { StoreInterface } from '@/models';

export interface AccountState {
  info: {
    name: string;
    cpfCnpj: string;
    address: string;
    contact: string;
    email: string;
    password: string;
  };
}

const initialState: AccountState = {
  info: {
    name: '',
    cpfCnpj: '',
    address: '',
    contact: '',
    email: '',
    password: '',
  },
};

const getters = {
  getAccountInfo(state: AccountState) {
    return state.info;
  },
};

const actions: ActionTree<AccountState, StoreInterface> = {

  async accountInfo({ commit, rootGetters }, uuid: string) {
    const url = `${process.env.VUE_APP_ULTRA_OCR_API_BASE_URL}/users/${uuid}`;
    try {
      const token = rootGetters['auth/getToken'];
      const head = {
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.get(url, { headers: head });
      console.log(response.data);
      commit('setAccountInfo', {});
    } catch (error) {
      console.log('error in accountInfo');
      throw TypeError(error);
    }
  },
};

const mutations: MutationTree<AccountState> = {
  setAccountInfo(state: AccountState, data: any) {
    state.info = {
      ...data,
    };
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
