<template>
  <v-container class="pa-0 mb-3">
    <v-row justify="center">
      <v-col>
        <Card
          id="totalRequests"
          :title="$t('dashboard.card.total')"
          :data="totalRequests"
          :subtitle="$t('dashboard.card.as-of', { date: date() })"
          :loaded="ready"
        />
      </v-col>
      <v-col>
        <Card :title="$t('dashboard.card.errors')" :data="totalErrors" :loaded="ready" />
      </v-col>
      <v-col>
        <Card
          :title="$t('dashboard.card.received')"
          :data="lastTwoHoursRequest"
          :subtitle="$t('dashboard.card.last')"
          :loaded="ready"
        />
      </v-col>
      <v-col>
        <Card
          :title="$t('dashboard.card.usage')"
          data="--"
          :subtitle="getRange()"
          :loaded="ready"
          disable
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Card from '@/components/Card.vue';
import localeDate from '../../../utils/localeDate';

export default {
  name: 'DashboardDataCards',
  components: {
    Card,
  },
  data: () => ({
    totalRequests: 0,
    totalErrors: 0,
    lastTwoHoursRequest: 0,
    ready: false,
  }),
  async mounted() {
    await this.$store.dispatch('metrics/requestPerHour');
    await this.$store.dispatch('metrics/monthRequests');
    this.totalRequests = await this.$store.getters['metrics/getTotalRequests'];
    this.totalErrors = await this.$store.getters['metrics/getTotalErrors'];
    const respLastTwoHours = await this.$store.getters['metrics/getRequestPerHour'];
    this.lastTwoHoursRequest = respLastTwoHours.values[0] + respLastTwoHours.values[1];
    this.ready = true;
  },
  methods: {
    date() {
      return new Date().toLocaleDateString(localeDate(), {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
    },
    getRange() {
      const today = new Date();
      const todayLabel = this.$t('dashboard.card.today');
      if (today.getDay() === 1) {
        return todayLabel;
      }
      const monthLabel = this.$t(`dashboard.months.${today.getMonth()}`);
      return this.$t('dashboard.card.range', { currentMonth: monthLabel, today: todayLabel });
    },
  },
};
</script>

<style></style>
