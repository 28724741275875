import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import store from '@/store';
import Dashboard from '@/views/Dashboard/Dashboard.vue';
import Api from '@/views/Api/Api.vue';
import ApiClient from '@/views/ApiClient/ApiClient.vue';
import Demo from '@/views/Demo/Demo.vue';
import Account from '@/views/Account/Account.vue';
import Billing from '@/views/Billing/Billing.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/billing',
    name: 'Billing',
    component: Billing,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/api',
    name: 'API',
    component: Api,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/api-client',
    name: 'API Client',
    component: ApiClient,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/demo/:id',
    name: 'Demo',
    component: Demo,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/account',
    name: 'Account',
    component: Account,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Auth/Login.vue'),
    meta: {
      hideForAuth: true,
    },
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () => import('@/views/Auth/ResetPassword.vue'),
    meta: {
      hideForAuth: true,
    },
  },
  {
    path: '/create-account',
    name: 'CreateAccount',
    component: () => import('@/views/Auth/CreateAccount.vue'),
    meta: {
      hideForAuth: true,
    },
  },
  {
    path: '/create-account-confirm',
    name: 'ConfirmAccount',
    component: () => import('@/views/Auth/ConfirmAccount.vue'),
    meta: {
      hideForAuth: true,
    },
  },
  { path: '*', redirect: '/' },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const loggedIn = await store.getters['auth/getLoggedIn'];
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const hideForAuth = to.matched.some((record) => record.meta.hideForAuth);
  if (requiresAuth && !loggedIn) {
    await store.dispatch('auth/logout');
    next('/login');
  }
  if (hideForAuth && loggedIn) {
    next({ path: '/' });
  }
  return next();
});

export default router;
