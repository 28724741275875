import Vue from 'vue';
import Vuex from 'vuex';

import app from './modules/app.module';
import auth from './modules/auth.module';
import i18n from './modules/i18n.module';
// eslint-disable-next-line import/named
import metrics from './modules/metrics.module';
import demo from './modules/demo.module';
import account from './modules/account.module';
import billing from './modules/billing.module';
import appClient from './modules/app-client.module';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    auth,
    i18n,
    metrics,
    demo,
    account,
    billing,
    appClient,
  },
});
