













import Vue from 'vue';

export default Vue.extend({
  name: 'btn',
  props: ['btnName', 'icon', 'onClick'],
});
