

































































































































import Vue from 'vue';
import Btn from '../../../components/Btn.vue';

interface DataModel {
  name: string;
  redirect_uris?: string[];
}

export default Vue.extend({
  name: 'CreateAppClient',
  components: { Btn },
  data() {
    return {
      scopes: [] as string[],
      useRedirectUrl: false,
      redirectUrl: '',
      clientName: '',
      dialog: false,
      app: {},
      rules: [(value) => !!value || 'Required.'],
    };
  },
  computed: {
    isDisabled(): boolean {
      return !this.clientName;
    },
  },
  methods: {
    remove(item) {
      this.scopes.splice(this.scopes.indexOf(item), 1);
      this.scopes = [...this.scopes];
    },
    async send() {
      this.$store.commit('app/setLoading', true);

      try {
        const data: DataModel = { name: this.clientName };
        if (this.useRedirectUrl) {
          data.redirect_uris = [this.redirectUrl];
        }

        await this.$store.dispatch('appClient/createApp', data);
        this.app = this.$store.getters['appClient/getAppCreated'];
        this.dialog = true;
        this.resetFields();
        this.$store.commit('app/setLoading', false);
      } catch (err) {
        this.$store.commit('app/setLoading', false);
        console.error(err);
      }
    },
    closeDialog() {
      this.dialog = false;
      this.$store.commit('appClient/setAppCreated', {});
    },
    resetFields() {
      this.clientName = '';
      this.scopes = [];
    },
  },
});
