






































































import Vue from 'vue';

export default Vue.extend({
  name: 'CreateAppClient',
  props: ['listAppData'],
  data() {
    return {
      dialog: false,
      itemSelected: {},
    };
  },
  methods: {
    openDialog(item) {
      this.dialog = true;
      this.itemSelected = item;
    },
    deleteApp(ClientId: string) {
      this.dialog = false;
      this.startLoading();
      this.$store
        .dispatch('appClient/deleteApp', ClientId)
        .then((response) => {
          if (response) {
            this.loadApps();
            this.successfullyDelete();
            this.stopLoading();
          }
        })
        .catch((error) => {
          this.errorDelete(error);
          this.stopLoading();
        });
    },
    loadApps() {
      this.$store.dispatch('appClient/loadApps');
    },
    startLoading() {
      this.$store.commit('app/setLoading', true);
    },
    stopLoading() {
      this.$store.commit('app/setLoading', false);
    },
    successfullyDelete() {
      this.$store.commit('app/callSnackBar', {
        type: 'success',
        text: this.$t('api_client.card_list.successfullyDeleteText'),
      });
    },
    errorDelete(error) {
      this.$store.commit('app/callSnackBar', {
        type: 'error',
        text: error,
      });
    },
  },
});
