






































































































































import Vue from 'vue';
import ProcessedFile from './components/ProcessedFile.vue';
import CodeSnippet from './components/CodeSnippet.vue';

export default Vue.extend({
  name: 'Demo',
  components: {
    ProcessedFile,
    CodeSnippet,
  },
  data() {
    return {
      testService: false,
      integrateService: false,
      processedFile: false,
      submited: false,
      processing: false,
      file: null,
      runner: this.$route.params.id,
    };
  },
  methods: {
    async submitFile() {
      this.submited = true;
      if (this.file) {
        this.processing = true;
        await this.$store.dispatch('demo/uploadFile', { file: this.file, runner: this.runner });
        this.submited = false;
        this.processing = false;
        this.processedFile = true;
        this.file = null;
      }
    },
  },
});
